.Not-found-page {
    display: flex;
    flex-shrink: 0;
    flex-direction: column;
    align-items: center;
}

.Not-found-page--button {
    height: 50px;
    font-size: 18px;
}