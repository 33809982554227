.Now {
    height: 100%;
}

.Now--white-section {
    background-color: #ffffff;
    padding: 10px;
    display: flex;
    flex-shrink: 0;
    justify-content: center;
}

.Now--margins {
    max-width: 500px;
}

.Now--header {
    text-align: center;
    margin: 25px;
}

.Now--sub-header {
    text-align: center;
    margin-bottom: 25px;
}

.Left-padding {
    padding-left: 20px;
    text-align: left;
}
