html, body, #root {
    height: 100%;
}

a:hover {
    text-decoration: none;
}

.Home-body {
    display: flex;
    flex-shrink: 0;
    flex-direction: column;
    height: 100%;
}

.Photo {
    width: 100%;
}

.Main-portion {
    position: relative;
    text-align: center;
    color: white;
    text-shadow: 0.3vw 0.3vw #000000;
}

.Main-portion--name {
    width: 80%;
    font-size: 7vw;
    position: absolute;
    top: 10%;
    left: 60%;
    transform: translate(-50%, -50%);
}

.Main-portion--job {
    width: 80%;
    font-size: 4vw;
    position: absolute;
    top: 42%;
    left: 60%;
    transform: translate(-50%, -50%);
}

.Projects-portion {
    background-color: #ffffff;
    display: flex;
    flex-grow: 1;
    flex-shrink: 0;
    flex-direction: column;
    align-items: center;
}

.Projects-portion--title {
    margin: 20px;
    font-size: 3rem;
}

.Projects-portion--sub-title {
    text-align: center;
    width: 100%;
    padding: 20px;
    margin: 0;
    font-size: 2rem;
}

.Projects-portion--projects {
    width: 100%;
    background-color: #ccebff;
    display: flex;
    flex-shrink: 0;
    justify-content: space-around;
    flex-wrap: wrap;
}

.navbar {
    flex-shrink: 0;
}