.navbar {
    width: 100%;
    background-color: #b3b3b3;
}

.nav-link {
    font-size: 20px;
    font-weight: bold;
}

.navbar-nav .nav-link {
    padding: 1rem;
}
