.About-me {
    display: flex;
    flex-shrink: 0;
    height: 100%;
    flex-direction: column;
    align-items: center;
}

.About-me-section {
    width: 100%;
    background-color: #ffffff;
    display: flex;
    flex-shrink: 0;
    padding: 25px;
    justify-content: center;
}

.About-me-non-white-section {
    width: 100%;
    background-color: #ccebff;
    display: flex;
    flex-shrink: 0;
    padding: 25px;
    justify-content: center;
}

.About-me-section__text {
    max-width: 500px;
    text-align: left;
}
